@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-caption {
  position: absolute !important;
  top: 20% !important;
  left: 10% !important;
  right: inherit !important;
  bottom: initial !important;
  padding-top: initial !important;
  padding-bottom: initial !important;
  color: #fff;
  text-align: center;
  max-width: 80%;
}

.carousel-caption h3 {
  font-weight: 400 !important;
  font-size: 32px !important;
  line-height: 39px !important;
  text-align: center !important;
  color: #EEEB4B !important;
}

.carousel-caption p {
  font-weight: 700 !important;
  font-size: 50px !important;
  line-height: 54px !important;
  color: #FFFFFF !important;
}

.video-responsive {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* padding-top: 56.25%; */
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.28);
}

.iframe-details-course {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.ytp-copylink-button {
  display: none !important;
}

textarea {
  height: 200px;
}

.copyright {
  font-size: 10px;
}

#btn-wa {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 999;
  cursor: pointer;
  text-align: end;
}

#btn-wa a img {
  width: 55px;
  height: 55px;
}

.price-discount {
  color: red;
  text-decoration: line-through;
}

.price-normal {
  font-size: 30px !important;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 430px) {
  #btn-wa {
    bottom: 30px;
    z-index: 999;
  }

  #btn-wa a img {
    width: 50px;
    height: 50px;
  }
}