.nav-link {
  cursor: pointer;
}

.btn-login {
  width: 131px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
}

.modal-content {
  border: none !important;
}

/* @layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
} */
.dyntube-iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 178.34%;
}

.dyntube-responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: transparent;
    right:10px;
    padding: 30px;
    color: #fff !important;
    fill: white !important;
    stroke: white !important;
}

#card-corp:hover > h6,
#card-corp:hover > span {
  color: #fff !important;
}