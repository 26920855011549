.pagination {
  display: flex;
  list-style: none;
  outline: none;
}

.active-page {
  background-color: #4070ff !important;
  border-color: #4070ff !important;
  color: #fff !important;
}
.pagination > li > a {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: #D9D9D9;
  margin: 0 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  outline: none;
  background: #eeeb4b !important;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  color: #0f7ca1 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.pagination > li > a,
.pagination > li > span {
  color: #373737;
  border-radius: 8px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
