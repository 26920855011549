.footer-bottom {
  color: #000 !important;
  width: 100%;
}

.section-logo-footer-component > img {
  width: 161px;
  height: 60px;
}

.container-footer-component {
  margin-top: 50px;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.logo-content {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.logo-content img {
  width: 100px;
  height: 40px;
  margin-bottom: 10px;
}

.container-footer-component > section > p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #000;
}

.heading-footer-component {
  font-weight: 650;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 35px;
  color: #000;
  text-shadow: 5px 5px 5px rgba(16, 76, 120, 0.05);
}

.link-footer-component {
  font-weight: 400;
  text-decoration: none;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #000;
}

.link-footer-component:hover {
  color: #fff;
}

.section-icon-footer-component {
  display: flex;
}

.section-icon-footer-component > a > .icon-footer-component {
  color: #000;
  width: 20px;
  height: 20px;
}

.section-icon-footer-component > a > .icon-footer-component:hover {
  color: #dd8b3f;
}

.container-copyright-footer-component  {
  width: 100%;
  border-top: 1px solid #fff;
  margin-top: auto;
}

.container-copyright-footer-component > article {
  display: flex;
  justify-content: center;
}

.container-copyright-footer-component > article > p {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000;
}

.container-copyright-footer-component > article > p > a {
  color: #000;
}

/* ==================================== */
@media screen and (max-width: 1440px) {
  .footer-component {
    margin: 0;
  }
}

/* =================================== */
@media screen and (max-width: 768px) {
  .footer {
    padding-top: 55px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-logo-footer-component {
    padding-left: 0;
  }

  .section-logo-footer-component > img {
    left: 0;
    width: 108px;
    height: 40px;
  }

  .container-footer-component {
    margin-top: 40px;
    grid-template-columns: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .container-footer-component > section {
    margin-bottom: 10px;
    max-width: 265px;
  }

  .border-bottom-footer {
    display: none !important;
  }

  .before-address-footer-component {
    margin-bottom: 0;
    color: #000 !important;
  }

  .address-footer-component ul{
    list-style: none !important;
  }

  .section-icon-footer-component {
    margin-bottom: 20px;
  }

  .logo-content {
    width: 200px;
    line-height: inherit;
  }

  .logo-content div p {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .container-footer-component > section > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }

  .container-footer-component > section > article > p {
    margin-bottom: 0;
  }

  .heading-footer-component {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 10px;
  }

  .link-footer-component {
    font-weight: 400;
    font-size: 14px;
    line-height: inherit;
  }

  .container-copyright-footer-component {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px !important;
  }

  .container-copyright-footer-component > article > p {
    margin-top: 28px;
    margin-bottom: 10px;
    font-size: 14px !important;
    line-height: inherit;
  }

  .before-address-footer-component .address-footer-component,
  .before-address-footer-component .address-footer-component div a {
    font-size: 14px !important;
    line-height: inherit;
  }
}

@media screen and (max-width: 430px) {
 .container-footer-component {
  overflow-y: hidden;
 }
  .heading-footer-component {
    color: #fff;
    margin: 10px 0;
  }

  .link-footer-component,
  .before-address-footer-component .address-footer-component,
  .before-address-footer-component .address-footer-component div a {
    color: #fff !important;
  }

  .container-copyright-footer-component  {
    border: none !important;
    margin-top: inherit;
    padding: 0;
  }
}